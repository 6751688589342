export const API_URL = {
  account: {
    root: 'account',
    details: 'details',
    login: 'authenticate',
    refreshAccessToken: 'token/refresh',
    forgotPassword: {
      root: 'reset-password',
      init: 'init',
      finish: 'finish'
    },
    activate: 'activate',
    changePassword: 'change-password',
    reinviteUser: 'send/activation/mail',
    setPassword: 'set/password'
  },
  users: {
    root: 'users',
    list: 'list',
    action: 'sfl-users'
  },
  events: {
    root: 'events',
    attendees: 'attendees',
    status: 'status',
    clone: 'clone',
    cancel: 'cancel'
  },
  feeds: {
    root: 'journey',
    comment: 'comment',
    report: 'report',
    action: 'action',
    details: 'details',
    reported: 'reported'
  },
  firebaseSubscription: {
    subscribe: 'subscribe',
    unsubscribe: ''
  },
  linkPreview: {
    apiURL: 'https://api.linkpreview.net',
    apiHeader: 'X-Linkpreview-Api-Key',
    apiHeaderKey: 'd3e4b7dddba1dfd87d2f3c7aa4f79fc6'
  },
  fileUpload: {
    root: 'file',
    sync: 'sync'
  },
  banners: {
    root: 'banner',
    list: 'list'
  },
  notification: {
    root: 'notification',
    broadcast: 'broadcast'
  }
};
